import Papa from 'papaparse'

export const parseCSV = function (contents, onDataParsed) {
  Papa.parse(contents, {
    header: true,
    skipEmptyLines: true,
    complete: function (result) {
      if (
        result.meta.fields &&
        result.meta.fields.length > 0 &&
        result.data.every(function (row) {
          return Object.keys(row).length === result.meta.fields.length
        })
      ) {
        const rows = result.data.map(function (row, index) {
          const filteredRow = {}
          for (const key in row) {
            if (row.hasOwnProperty(key) && key !== null && key.trim() !== '') {
              filteredRow[key] = row[key]
            }
          }
          return Object.assign({ id: index + 1 }, filteredRow)
        })
        onDataParsed(rows)
      } else {
        onDataParsed([])
      }
    }
  })
}
