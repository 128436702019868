import { ApiResponse, requestJson, useApiGet } from '@papertrail/web3-utils'

export type FolderInfo = {
  id: string
  name: string
  children?: FolderInfo[]
}

export const useApiGetFolders = (accountId: string, rootFolderId: string, existing: FolderInfo[]) => {
  function mapFoldersWithExisting(data: ApiResponse): FolderInfo[] {
    return data.data.map((folderData: ApiResponse) => ({
      id: folderData.id,
      name: folderData.name,
      children: folderData.children.data ? mapFolders(folderData.children) : null
    }))
  }
  return useApiGet(`/accounts/${accountId}/folders/${rootFolderId}`, mapFoldersWithExisting)
}

export const mapFolders = (data: ApiResponse): FolderInfo[] => {
  return data.data.map((folderData: ApiResponse) => ({
    id: folderData.id,
    name: folderData.name,
    children: folderData.children.data ? mapFolders(folderData.children) : null
  }))
}

export const uploadImport = async (accountId, body, importId) => {
  const result = await requestJson({
    path: `/accounts/${accountId}/data_import/${importId}/upload`,
    method: 'POST',
    body: body
  })
  return result.data
}
