import {
  AlertWarning,
  BasicPagination,
  Box,
  ButtonDanger,
  ContentWrapper,
  FileUploadIcon,
  FlexRow,
  Loading,
  NoData,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@papertrail/styleguide'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ImportStatus } from './ImportStatus'
import { EventManager, useApiDelete, useApiGet } from '@papertrail/web3-utils'
import { useParams } from 'react-router-dom'
import ConfirmDialog from './ConfirmDialog'

type Props = {
  onEdit: (data) => void
  reRender: boolean
}

type Params = {
  accountid: string
}

const ImportList = (props: Props) => {
  const { onEdit, reRender } = props
  const { accountid } = useParams() as Params
  const [importList, loadImportList] = useApiGet<any>(`/accounts/${accountid}/data_import`, (data) => {
    const listData = data.data.map((n) => {
      return {
        id: n.id,
        name: n.file_name,
        recordCount: n.row_count,
        updatedAt: n.updated_at,
        uploadedBy: `${n.user.data.first_name} ${n.user.data.last_name}`,
        status: n.state
      }
    })
    const meta = data.meta
    const mappedData = { listData, meta }
    return mappedData
  })
  const [idToDelete, setIdToDelete] = useState<string>('')
  const [deleteResp, importDelete] = useApiDelete<any>(
    `/accounts/${accountid}/data_import/${idToDelete}`,
    (data) => data
  )
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false)

  useEffect(() => {
    if (accountid && reRender === true && !importList.isLoading) {
      loadImportList({ limit: 50, include: 'user' })
    }
  }, [reRender])

  useEffect(() => {
    if (accountid) {
      loadImportList({ limit: 50, include: 'user' })
    }
  }, [])

  useEffect(() => {
    const listenerRef = EventManager.subscribe(['data_import_state_was_updated'], (data: any) => {
      console.log(data, 'data')
      if (!importList.isLoading) {
        loadImportList({ limit: 50, include: 'user' })
      }
    })
    return () => {
      EventManager.unsubscribe(listenerRef)
    }
  }, [])

  useEffect(() => {
    if (deleteResp.isLoaded) {
      setIdToDelete('')
      loadImportList({ limit: 50, page: 1, include: 'user' })
    }
  }, [deleteResp.isLoaded])

  const changePage = (event, page) => {
    loadImportList({ limit: 50, page: page, include: 'user' })
  }

  const { t } = useTranslation(['tools', 'global'])

  const goToImport = (item) => {
    if (item.status !== 'Loading' && item.status !== 'Loaded' && item.status !== 'Loading Errors') {
      onEdit(item)
    }
  }

  const deleteImport = (item) => {
    setIdToDelete(item.id)
    setConfirmDialogOpen(true)
  }

  const clearCancel = () => {
    setConfirmDialogOpen(false)
    setIdToDelete('')
  }

  const clearConfirm = () => {
    if (idToDelete !== '') {
      importDelete({})
      setConfirmDialogOpen(false)
    }
  }

  return (
    <ContentWrapper>
      {importList.isLoading && <Loading />}
      {importList.isError && <AlertWarning>There was an error loading the import list.</AlertWarning>}
      {importList.isLoaded && importList.data.listData.length > 0 && (
        <>
          <Box overflow={'auto'}>
            <Table stickyHeader sx={{ minWidth: 650 }} aria-label={t('tagTable')}>
              <TableHead>
                <TableRow>
                  <TableCell>{t('Import Name')}</TableCell>
                  <TableCell>{t('Record Count')}</TableCell>
                  <TableCell>{t('Updated At')}</TableCell>
                  <TableCell>{t('Uploaded By')}</TableCell>
                  <TableCell>{t('Status')}</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {importList.data.listData.map((item) => {
                  if (item.recordCount > 0) {
                    return (
                      <TableRow
                        key={item.id}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 }, cursor: 'pointer' }}>
                        <TableCell onClick={() => goToImport(item)}>{item.name}</TableCell>
                        <TableCell onClick={() => goToImport(item)} sx={{ cursor: 'pointer' }}>
                          {item.recordCount}
                        </TableCell>
                        <TableCell onClick={() => goToImport(item)}>
                          {new Date(item.updatedAt).toLocaleDateString('en-uk', {
                            year: 'numeric',
                            month: 'short',
                            day: 'numeric'
                          })}
                        </TableCell>

                        <TableCell onClick={() => goToImport(item)}>{item.uploadedBy}</TableCell>
                        <TableCell onClick={() => goToImport(item)}>
                          <ImportStatus status={item.status}></ImportStatus>
                        </TableCell>
                        <TableCell>
                          <ButtonDanger onClick={() => deleteImport(item)}>Delete</ButtonDanger>
                        </TableCell>
                      </TableRow>
                    )
                  }
                })}
              </TableBody>
            </Table>
          </Box>
          {importList.isLoaded && importList.data.listData.length > 0 && (
            <FlexRow justifyContent={'center'}>
              <BasicPagination
                count={importList.data.meta.pagination.total_pages}
                page={importList.data.meta.pagination.current_page}
                handleChange={changePage}
              />
            </FlexRow>
          )}
        </>
      )}
      {importList.isLoaded && importList.data.listData.length === 0 && (
        <NoData icon={FileUploadIcon} text={'No Imports'} />
      )}
      {confirmDialogOpen && <ConfirmDialog onCancel={clearCancel} onConfirm={clearConfirm} />}
    </ContentWrapper>
  )
}

export default ImportList
