import { Table, TableHead, TableRow, TableCell, TableBody, Box, Switch } from '@mui/material'
import { FieldArray, FlexRow, Form, FormFieldSelectFormik, Formik, Loading } from '@papertrail/styleguide'
import React, { useEffect, useState } from 'react'
import * as yup from 'yup'

type Props = {
  uploadData: any
  blueprintState: any
  isFormValid: (boolean) => void
  updatedData: (data) => void
  existingMapping
}

export const MatchColumns = (props: Props) => {
  const { uploadData, isFormValid, updatedData, blueprintState, existingMapping } = props
  const [columns, setColumns] = useState([])
  const [selectedFields, setSelectedFields] = useState(null)
  const [columnFilter, setColumnFilter] = useState(false)

  const removeItemsWithSameValue = () => {
    const uniqueColumns = blueprintState.filter(
      (column, index, self) => index === self.findIndex((c) => c.label === column.label)
    )
    setColumns(uniqueColumns)
  }

  const checkInitalData = () => {
    const uniqueColumns = blueprintState.filter(
      (column, index, self) => index === self.findIndex((c) => c.label === column.label)
    )
    const fields = uploadData.map((data, index) => {
      const matchingField =
        blueprintState && uniqueColumns.find((field) => field.label.toLowerCase() === data.toLowerCase())
      return {
        userOption: matchingField ? 'match' : 'ignore',
        fieldName: matchingField ? matchingField.value : ''
      }
    })

    updatedData({
      fields: fields
    })
    isFormValid(true)
  }

  useEffect(() => {
    if (blueprintState && blueprintState.length > 0) {
      removeItemsWithSameValue()
      if (uploadData && uploadData.length > 0) {
        checkInitalData()
        setSelectedFields(
          uploadData.map((value, index) => {
            return {
              value: value,
              index: index
            }
          })
        )
      } else {
        setSelectedFields(
          existingMapping
            .map((item, index) => {
              if (item.blueprint_key) {
                return {
                  value: item.blueprint_key,
                  index: index
                }
              }
              return undefined
            })
            .filter((item) => item !== undefined)
        )
        isFormValid(true)
      }
    }
  }, [blueprintState])

  if (blueprintState.length === 0 || columns.length === 0) {
    return (
      <div>
        <Loading />
      </div>
    )
  }
  const initialValues = {
    fields:
      uploadData && uploadData.length > 0
        ? uploadData.map((data, index) => {
            const matchingField =
              blueprintState && columns.find((field) => field.label.toLowerCase() === data.toLowerCase())
            return {
              userOption: matchingField ? 'match' : 'ignore',
              fieldName: matchingField ? data.toLowerCase() : ''
            }
          })
        : []
  }

  const updatedValues = {
    fields:
      existingMapping && existingMapping.length > 0
        ? existingMapping.map((item: { column: string; blueprint_key: string }, index) => {
            const value = item.blueprint_key
            const blueprintField = value && blueprintState && columns.find((field) => field.value === value)
            return {
              userOption: value && value !== 'null' ? 'match' : 'ignore',
              fieldName: blueprintField ? blueprintField.label.toLowerCase() : ''
            }
          })
        : []
  }

  const validationSchema = yup.array().of(
    yup.object({
      userOption: yup
        .string()
        .required('Action is required')
        .oneOf(['ignore', 'match'], 'Please select a valid action'),
      fieldName: yup.string().when('userOption', {
        is: (userOption) => userOption === 'ignore',
        then: yup.string(),
        otherwise: yup
          .string()
          .required('Field name is required')
          .notOneOf(['null', null, ''], 'Please select a valid action')
      })
    })
  )

  const selectField = (value, index, values) => {
    const existingIndex = selectedFields.findIndex((field) => field.index === index)

    const updatedFields = [...selectedFields]
    if (existingIndex !== -1) {
      updatedFields[existingIndex] = { value: value, index: index }
    } else {
      updatedFields.push({ value: value, index: index })
    }
    setSelectedFields(updatedFields)

    // Update the corresponding userOption field to 'match'
    values.setFieldValue(`fields.${index}.userOption`, 'match')
  }

  const getAvailableFields = (index, values) => {
    const currentValue = values.fields[index].fieldName
    return columns
      .filter((field) => {
        return !selectedFields.some(
          (selected) =>
            selected.value.toLowerCase() === field.label.toLowerCase() &&
            selected.index !== index &&
            selected.value.toLowerCase() !== currentValue.toLowerCase()
        )
      })
      .map((field) => ({
        label: field.label,
        value: field.label.toLowerCase()
      }))
  }

  const validateData = (data) => {
    const fields = data.fields
    const filteredFields = fields.filter((element) => element.userOption !== 'ignore')

    const updatedFields = fields.map((field) => {
      const index = blueprintState.findIndex((item) => item.label.toLowerCase() === field.fieldName.toLowerCase())
      const updatedFieldName = index !== -1 ? blueprintState[index].value : field.fieldName

      return {
        ...field,
        fieldName: updatedFieldName
      }
    })

    const hasNullInFiltered = filteredFields.some(hasNull)

    if (!hasNullInFiltered) {
      isFormValid(!hasNullInFiltered)
      updatedData({
        fields: updatedFields
      })
    }
  }

  function hasNull(element, index, array) {
    return Object.keys(element).some(function (key) {
      return element[key] === 'null'
    })
  }

  const handleUserOptionChange = (event, values, index) => {
    const updatedFields = [...values.values.fields]
    const selectedField = selectedFields.find((field) => field.index === index)

    if (event.target.value === 'ignore') {
      updatedFields[index].fieldName = ''

      // Remove the field from the selectedFields array
      const updatedSelectedFields = selectedFields.filter((field) => field.index !== index)
      setSelectedFields(updatedSelectedFields)
    } else {
      // Add the field to the selectedFields array
      if (!selectedField) {
        setSelectedFields([
          ...selectedFields,
          {
            value:
              existingMapping && existingMapping.length > 0 ? existingMapping[index].blueprint_key : uploadData[index],
            index: index
          }
        ])
      }
    }

    values.setFieldValue(`fields.${index}.fieldName`, updatedFields[index].fieldName)
  }

  if ((initialValues.fields.length > 0 || updatedValues.fields.length > 0) && columns && columns.length > 0) {
    return (
      <Formik
        initialValues={updatedValues.fields && updatedValues.fields.length > 0 ? updatedValues : initialValues}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          validateData(values)
        }}
        validateOnChange={false}>
        {(values) => (
          <Form style={{ marginBottom: '250px' }}>
            <FlexRow justifyContent={'space-between'} sx={{ marginBottom: '4px' }}>
              <div>
                {' '}
                <Switch
                  checked={columnFilter === true}
                  onChange={() => {
                    setColumnFilter(!columnFilter)
                  }}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
                Show only unmatched fields
              </div>
            </FlexRow>
            <Table stickyHeader sx={{ minWidth: 650, fontSize: '14px', fontFamily: 'Inter', borderTop: 0 }}>
              <TableHead sx={{ borderTop: 0 }}>
                <TableRow>
                  <TableCell sx={{ fontSize: '14px', fontFamily: 'Inter', fontWeigth: '600px' }}>CSV Column</TableCell>
                  <TableCell></TableCell>
                  <TableCell sx={{ fontSize: '14px', fontFamily: 'Inter', fontWeigth: '600px' }}>
                    Papertrail Field
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <FieldArray name="fields">
                  {({ insert, remove, push }) => (
                    <>
                      {values.values.fields.map((item, index) => {
                        if (
                          (item.fieldName !== 'id' && columnFilter == false) ||
                          (columnFilter === true && item.userOption === 'ignore')
                        ) {
                          return (
                            <TableRow
                              key={index}
                              sx={{
                                '&:last-child td, &:last-child th': { border: 0 },
                                borderColor: 'blue',
                                backgroundColor:
                                  values.values.fields[index].userOption === 'match' &&
                                  values.values.fields[index].fieldName !== 'null'
                                    ? '#E5F2FF'
                                    : 'inherit'
                              }}>
                              <TableCell
                                sx={{ fontSize: '14px', fontFamily: 'Inter', fontWeigth: '600px', width: '300px' }}>
                                {existingMapping && existingMapping.length > 0
                                  ? existingMapping[index].column
                                  : uploadData[index]}
                              </TableCell>
                              <TableCell sx={{ cursor: 'pointer', width: '500px' }}>
                                <FlexRow justifyContent="right">
                                  <Box sx={{ width: '40%' }} alignSelf={'right'}>
                                    <FormFieldSelectFormik
                                      name={`fields.${index}.userOption`}
                                      label={''}
                                      placeholder={'Select Option'}
                                      options={[
                                        { label: 'Match Column', value: 'match' },
                                        { label: 'Remove Column', value: 'ignore' }
                                      ]}
                                      value={values.values.fields[index].userOption}
                                      onChange={(event) => {
                                        handleUserOptionChange(event, values, index)
                                        values.handleChange(event)
                                        values.handleSubmit()
                                      }}
                                      backgroundColour="white"
                                      fullWidth></FormFieldSelectFormik>
                                  </Box>
                                </FlexRow>
                              </TableCell>

                              <TableCell>
                                <Box sx={{ width: '40%' }}>
                                  <FormFieldSelectFormik
                                    name={`fields.${index}.fieldName`}
                                    label={''}
                                    placeholder={'Select Field'}
                                    options={getAvailableFields(index, values.values)}
                                    value={values.values.fields[index].fieldName}
                                    onChange={(event) => {
                                      values.handleChange(event)
                                      values.handleSubmit()
                                      selectField(event.target.value, index, values)
                                    }}
                                    backgroundColour="white"
                                    fullWidth></FormFieldSelectFormik>
                                </Box>
                              </TableCell>
                            </TableRow>
                          )
                        } else {
                          return null
                        }
                      })}
                    </>
                  )}
                </FieldArray>
              </TableBody>
            </Table>
          </Form>
        )}
      </Formik>
    )
  }
}
