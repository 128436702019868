import React, { useCallback, useEffect, useState } from 'react'

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  ButtonPrimary,
  ButtonSecondary,
  FlexRow,
  Box,
  CheckCircleIcon,
  ErrorIcon,
  WarningIcon,
  ProductSearchAndSelect
} from '@papertrail/styleguide'
import { debounce } from '@mui/material'
import { useParams } from 'react-router-dom'
import { useApiGet } from '@papertrail/web3-utils'

type Props = {
  onCancel: () => void
  onConfirm: (data) => void
  products
  row
}

type Params = {
  accountid: string
}

const ProductDialogue = (props: Props) => {
  const { accountid } = useParams() as Params
  const { onCancel, onConfirm, products, row } = props
  const { id, product_id } = row
  const productIds = products[id] && products[id] ? products[id] : []
  const [selectedProduct, setSelectedProduct] = useState(null)
  const [hideInitialResults, setHide] = useState(false)
  const Mapper = (data) => {
    return data.data
  }

  const [productSearch, loadProducts] = useApiGet(`/accounts/${accountid}/products/search`, Mapper)
  const [product, searchProduct] = useApiGet(`/products/search/${product_id}`, Mapper)
  const [productResults, searchProductBulk] = useApiGet(`/products/search/bulk`, Mapper)

  useEffect(() => {
    setSelectedProduct(null)
    setHide(false)
  }, [onCancel, onConfirm])

  useEffect(() => {
    if (product_id) {
      searchProduct({})
    }
  }, [product_id])

  useEffect(() => {
    if (!product_id && productIds && productIds.length > 1) {
      searchProductBulk({ uuid: productIds.join(',') })
    }
  }, [productIds])

  useEffect(() => {
    if (product.isLoaded && product.data) {
      setSelectedProduct(product.data)
    }
  }, [product.isLoaded])

  const getText = () => {
    if (productIds.length === 0 && !product_id) {
      return 'No Product Matches Found '
    }
    return product_id || productIds.length === 1 ? `Match Found ` : `Multiple Product Matches Found`
  }

  const getIcon = () => {
    const style = { height: '23px', width: '23px', alignSelf: 'center' }
    if (productIds.length === 0 && !product_id) {
      return <ErrorIcon color="error" style={style} />
    }
    return product_id || productIds.length === 1 ? (
      <CheckCircleIcon color="success" style={style} />
    ) : (
      <WarningIcon color="warning" style={style} />
    )
  }

  const searchProducts = (str: string) => {
    loadProducts({ q: str, limit: 25, page: 1 })
  }

  const refreshCallback = useCallback(debounce(searchProducts, 200), [])

  function onSearchChange(str: string) {
    if (!hideInitialResults) {
      setHide(true)
    }

    refreshCallback(str)
  }

  const productSelected = (item) => {
    setSelectedProduct(item)
  }

  return (
    <Dialog open={true}>
      <DialogTitle>Product Match</DialogTitle>

      <DialogContent>
        <FlexRow justifyContent="left" alignItems="center">
          <Box>{getIcon()}</Box>
          <Box sx={{ marginLeft: '20px' }}>
            {getText()} <br />
            <strong>
              {row?.manufacturer} - {row?.product_code}
            </strong>
          </Box>
        </FlexRow>
        {!selectedProduct &&
          productResults.isLoaded &&
          productResults.data.length > 0 &&
          productResults.data.map((item: any) => {
            return (
              <FlexRow
                justifyContent="space-around"
                sx={{
                  marginTop: '28px',
                  backgroundColor: selectedProduct && item.id === selectedProduct.id ? '#E5F2FF' : 'inherit',
                  padding: '16px'
                }}
                key={item.id}>
                <Box>
                  {' '}
                  <img src={item.images[0]} alt={'Product Image'} width={'80px'} />
                </Box>
                <Box display="flex" flexDirection={'column'} sx={{ marginLeft: '24px', marginRight: '24px' }}>
                  <h4> {item.name}</h4>
                  <div style={{ fontSize: '12px' }}>
                    <strong>Manufacturer:</strong> {item.manufacturer && item.manufacturer}
                  </div>
                  <div style={{ fontSize: '12px' }}>
                    <strong>Description:</strong> {item.description}
                  </div>
                  <div style={{ fontSize: '12px' }}>
                    <strong>Category:</strong>{' '}
                    {item.fields.data.find((item) => item.key === 'category') &&
                      item.fields.data.find((item) => item.key === 'category').value}
                  </div>
                  <div style={{ fontSize: '12px' }}>
                    <strong>Product Code: </strong>
                    {item.fields.data.find((item) => item.key === 'product_code') &&
                      item.fields.data.find((item) => item.key === 'product_code').value}
                  </div>
                </Box>
                <Box>
                  <ButtonPrimary onClick={() => setSelectedProduct(item)} data-testid="select_product">
                    SELECT
                  </ButtonPrimary>
                </Box>
              </FlexRow>
            )
          })}
        {selectedProduct && (
          <FlexRow
            justifyContent="space-around"
            sx={{
              marginTop: '28px',
              backgroundColor: '#E5F2FF',
              padding: '16px'
            }}>
            <Box>
              {' '}
              <img src={selectedProduct.images[0]} alt={'Product Image'} height={'73px'} />
            </Box>
            <Box display="flex" flexDirection={'column'} sx={{ marginLeft: '24px', marginRight: '24px' }}>
              <h2> {selectedProduct.name}</h2>
              <div>
                <strong>Manufacturer:</strong> {selectedProduct.manufacturer && selectedProduct.manufacturer}
              </div>
              <div>
                <strong>Description:</strong> {selectedProduct.description}
              </div>
              <div>
                <strong>Category:</strong>{' '}
                {selectedProduct.fields.data.find((selectedProduct) => selectedProduct.key === 'category') &&
                  selectedProduct.fields.data.find((selectedProduct) => selectedProduct.key === 'category').value}
              </div>
              <div>
                <strong>Product Code: </strong>
                {selectedProduct.fields.data.find((selectedProduct) => selectedProduct.key === 'product_code') &&
                  selectedProduct.fields.data.find((selectedProduct) => selectedProduct.key === 'product_code').value}
              </div>
            </Box>
          </FlexRow>
        )}
        <Box display="flex" flexDirection={'column'} justifyContent={'left'}>
          <h3>Search and add from the Product Directory</h3>
          <ProductSearchAndSelect
            apiResults={productSearch}
            onSelect={productSelected}
            onSearchTermChange={onSearchChange}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <ButtonSecondary onClick={onCancel}>Cancel</ButtonSecondary>
        <ButtonPrimary
          onClick={() => onConfirm(selectedProduct)}
          data-testid="confirm"
          disabled={selectedProduct === null}>
          Confirm
        </ButtonPrimary>
      </DialogActions>
    </Dialog>
  )
}

export default ProductDialogue
