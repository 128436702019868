import { Box, FlexRow, CheckCircleIcon, DeleteIcon } from '@papertrail/styleguide'
import React from 'react'

type Props = {
  uploadData: any
  name: string
  handleDelete: () => void
}

export const UploadRow = (props: Props) => {
  const { uploadData, name, handleDelete } = props
  const rows = uploadData.length
  const columns = Object.keys(uploadData[0]).map((key) => key)
  const onDelete = () => {
    handleDelete()
  }
  return (
    <>
      <FlexRow
        justifyContent={'space-between'}
        sx={{
          borderRadius: '4px',
          border: '1px solid var(--text-color-text-disabled, #ABB4C4)',
          boxShadow: '0px 4px 4px 0px rgba(169, 169, 169, 0.4)',
          marginTop: '8px',
          height: '26%'
        }}>
        <Box sx={{ display: 'flex' }}>
          <Box
            sx={{
              padding: '42px',
              display: 'flex',
              justifyContent: 'center',
              alignContent: 'center',
              alignItems: 'center'
            }}>
            {' '}
            <CheckCircleIcon color="success" style={{ height: '23px', width: '23px', alignSelf: 'center' }} />
          </Box>

          <Box>
            <p style={{ color: '#343741' }}>{name}</p>
            <p style={{ fontSize: '12px', color: '#69707D' }}>{columns.length + ' fields'}</p>
            <p style={{ fontSize: '12px', color: '#69707D' }}>{rows + ' records'}</p>
          </Box>
        </Box>
        <Box>
          <Box
            sx={{
              padding: '42px',
              display: 'flex',
              justifyContent: 'center',
              alignContent: 'center',
              alignItems: 'center'
            }}>
            {' '}
            <span onClick={onDelete}>
              <DeleteIcon color="secondary" style={{ height: '23px', width: '23px', alignSelf: 'center' }} />
            </span>
          </Box>
        </Box>
      </FlexRow>
    </>
  )
}
