import { Box, FlexRow, DeleteIcon, ErrorIcon } from '@papertrail/styleguide'
import React from 'react'

type Props = {
  handleDelete: () => void
}

export const ErrorRow = (props: Props) => {
  const { handleDelete } = props
  const onDelete = () => {
    handleDelete()
  }
  return (
    <>
      <FlexRow
        justifyContent={'space-between'}
        sx={{
          borderRadius: '4px',
          border: '1px solid #EB3745',
          background: '#FDEBEC',
          boxShadow: '0px 4px 4px 0px rgba(235, 55, 69, 0.12)',
          marginTop: '32px',
          height: '20%'
        }}>
        <Box sx={{ display: 'flex' }}>
          <Box
            sx={{
              padding: '42px',
              display: 'flex',
              justifyContent: 'center',
              alignContent: 'center',
              alignItems: 'center'
            }}>
            {' '}
            <ErrorIcon color="error" style={{ height: '23px', width: '23px', alignSelf: 'center' }} />
          </Box>

          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignContent: 'center',
              alignItems: 'center'
            }}>
            <p style={{ color: '#EB3745' }}>
              Error: Invalid file format. For the data import, please ensure that you upload a CSV file containing both
              column names (representing fields) and data rows (representing records). The file should follow the
              standard CSV format with meaningful column headers and associated record values.
            </p>
          </Box>
        </Box>
        <Box>
          <Box
            sx={{
              padding: '42px',
              display: 'flex',
              justifyContent: 'center',
              alignContent: 'center',
              alignItems: 'center'
            }}>
            {' '}
            <span onClick={onDelete}>
              <DeleteIcon color="secondary" style={{ height: '23px', width: '23px', alignSelf: 'center' }} />
            </span>
          </Box>
        </Box>
      </FlexRow>
    </>
  )
}
