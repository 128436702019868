import { Box, Chip, CheckIcon, MissingStateIcon, SearchIcon } from '@papertrail/styleguide'
import React from 'react'

export enum importState {
  inProgress = 'In Progress',
  processing = 'Loading...',
  completed = 'Completed',
  failed = 'Failed'
}

type Props = {
  status: string
}

export const ImportStatus = (props: Props) => {
  const importStatus = props.status
  const importStatuses = {
    inProgress: {
      name: importState.inProgress,
      colour: '#F7A503',
      icon: (
        <div style={{ display: 'flex', justifyContent: 'center', color: 'white' }}>
          <SearchIcon />
        </div>
      )
    },
    processing: {
      name: importState.processing,
      colour: '#007AFF',
      icon: null
    },
    completed: {
      name: importState.completed,
      colour: '#0B996A',
      icon: (
        <div style={{ display: 'flex', justifyContent: 'center', color: 'white' }}>
          <CheckIcon />
        </div>
      )
    },
    failed: {
      name: importState.failed,
      colour: '#EB3745',
      icon: (
        <div style={{ display: 'flex', justifyContent: 'center', color: 'white' }}>
          <MissingStateIcon />
        </div>
      )
    }
  }

  const getImportState = () => {
    switch (importStatus) {
      case 'Loading':
        return 'processing'
        break
      case 'Loaded':
        return 'completed'
        break
      case 'Loading Errors':
        return 'failed'
        break
      default:
        return 'inProgress'
        break
    }
  }

  return (
    <Box display={'flex'} alignItems={'center'}>
      <Chip
        sx={{
          backgroundColor: importStatuses[getImportState()]['colour'],
          borderColor: importStatuses[getImportState()]['colour'],
          color: 'white',
          fontSize: '14px',
          textTransform: 'capitalize',
          padding: '12px'
        }}
        icon={importStatuses[getImportState()].icon}
        label={importStatuses[getImportState()].name}
        variant="outlined"
      />
    </Box>
  )
}
