import { MyStepper } from '@papertrail/styleguide'
import * as React from 'react'
import { useEffect, useState } from 'react'

type Props = {
  currentStep: number
}

const ImportSteps = (props: Props) => {
  const steps = ['Upload File', 'Match Columns', 'Validate Data', 'Add to Papertrail']
  const [currentStep, setCurrentStep] = useState<any>(props.currentStep)

  useEffect(() => {
    setCurrentStep(props.currentStep)
  }, [props.currentStep])

  return <MyStepper steps={steps} currentStep={currentStep}></MyStepper>
}

export default ImportSteps
