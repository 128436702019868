import React from 'react'

export const HelperText = {
  popoverContents: [
    // Step 1
    <>
      <b>Upload File</b>
      <div>
        Please upload your import in CSV format. If you have multiple sheets you will need to upload each sheet
        separately.
      </div>
    </>,
    // Step 2
    <>
      <b>Match Columns</b>
      <div>
        We automatically match the columns/field names in your CSV with the fields available on your record blueprint.
        Any that have not been automatically matched you will need to do manually using the blueprint field dropdowns.
      </div>
    </>,
    // Step 3
    <>
      <b>Validate Data</b>
      <div>
        Review your import data and correct any errors found by the Papertrail system. The grid that contains the data
        works very similarly to an excel spreadsheet.
      </div>
    </>,
    // Step 4
    <>
      <b>Add to Papertrail</b>
      <div>
        The Product Match column shows if a single matching product, multiple products, or no products have been found
        for each record. Records that are matched with products will be imported into the system from the Papertrail
        directory.
      </div>
    </>
  ]
}
